import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "load",
  id: "load",
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_select, {
      class: "select-default",
      placeholder: "placeholder",
      clearable: true,
      id: "id",
      options: _ctx.options,
      modelValue: _ctx.valueSelect,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueSelect) = $event)),
      onOpen: _ctx.onOpen,
      onSearch: _ctx.onSearch,
      selectable: _ctx.func
    }, {
      "list-footer": _withCtx(() => [
        (_ctx.hasNextPage)
          ? (_openBlock(), _createElementBlock("li", _hoisted_1, " Loading more options... ", 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["options", "modelValue", "onOpen", "onSearch", "selectable"])
  ]))
}