
import { defineComponent, onMounted, reactive, ref, nextTick, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import apiUseCase from '@/usecase/apiUseCase'
import vSelect from 'vue-select'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import { useIntersectionObserver } from '@vueuse/core'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'TrayekForm',
  components: {
    vSelect
  },
  props: {
    trayekType: {
      type: String,
      default: () => ''
    },
    selectableParams: {
      type: Array as any,
      default: () => ['Trayek', null]
    }
  },
  setup(props: any, { emit } : any) {
    const { selectableParams } = toRefs(props)
    const isLoading = ref(false)
    const options = ref([]) as any
    const valueSelect = ref()
    const inputSearch = ref('')
    const searchFields = ref(['Nopol'])
    const searchCustom = ref([]) as any
    const fieldLabel = ref('Nopol')
    const fieldValue = ref('Id')
    const endpoint = ref('/management/v1/MsVehicle')
    const hasNextPage = ref(false)
    const load = ref(null) as unknown as HTMLElement
    const targetIsVisible = ref(false)

    // const infiniteScroll = ([{ x, y }]) => {
    //   console.log('xy', [x, y])
    // }
    // const observer = new IntersectionObserver(infiniteScroll)
    // const { stop } = useIntersectionObserver(load, ([{ isIntersecting }], observerElement) => {
    //   targetIsVisible.value = isIntersecting
    //   console.log('xxx')
    //   if (targetIsVisible.value) {
    //     getData()
    //   }
    // })

    const pagination = {
      count: 0,
      pageNumber: 1,
      pageSize: 10,
      limit: 0,
      // observer: new IntersectionObserver(infiniteScroll)
    }

    // eslint-disable-next-line consistent-return
    const func = (val: any) => {
      console.log('val', val)
      if (selectableParams.value.length > 0) {
        if (val[`${selectableParams.value[0]}`] === selectableParams.value[1]) {
          return true
        }
        return false
      }
    }

    const getData = async () => {
      try {
        // isLoading.value = true
        searchCustom.value = [['pageNumber', '=', pagination.pageNumber]]
        const { result, error, count, pagenumber } = await apiUseCase.get(`${endpoint.value}${returnUrlPrams({
          search: inputSearch.value,
          // filterField: isEmpty(searchFields.value)
          filterField: searchFields.value,
          custom: searchCustom.value
        })}`)
        if (!error) {
          console.log('response', error)
          if (pagenumber === 1) {
            pagination.limit = 0
            pagination.count = 0
            options.value = []
            console.log('optionsreset', [inputSearch.value, options.value])
          }
          pagination.limit = count
          pagination.count += result.length
          console.log([pagination.limit, pagination.count])
          if (pagination.count < pagination.limit) {
            hasNextPage.value = true
          } else {
            hasNextPage.value = false
          }
          // hasNextPage.value = pagination.count < pagination.limit

          console.log('asadad', hasNextPage.value)
          // eslint-disable-next-line array-callback-return
          result.map((item: any) => {
            const data = {
              ...item,
              label: item[fieldLabel.value],
              value: item[fieldValue.value]
            }
            options.value.push(data)
          })
          // console.log('mapData', mapData)
          // options.value = mapData
          console.log('options', options.value)
        }
        isLoading.value = false
      } catch (err) {
        isLoading.value = false
      }
    }

    // const observer = new IntersectionObserver(infiniteScroll)

    const onOpen = async () => {
      // options.value = []
      if (pagination.count === 0) {
        getData()
      }
      // if (hasNextPage.value) {
      //   await nextTick()
      //   // console.log('x', x)
      //   console.log(document.querySelector('#load'))
      //   const y = document.querySelector('#load')!
      //   observer.observe(y)
      // }
      // getData()
    }

    const onSearch = (val: any) => {
      console.log('valSearch', val)
      if (val !== inputSearch.value) {
        pagination.pageNumber = 1
        options.value = []
        hasNextPage.value = false
        inputSearch.value = val
        // setTimeout(getData, 500)
        getData()
        // debounce(getData, 500)
      }

      // debounce(getData, 100)
    }

    const { stop } = useIntersectionObserver(load, ([{ isIntersecting }], observerElement) => {
      targetIsVisible.value = isIntersecting
      const ul: any = (document.querySelector('#load') as HTMLElement).offsetParent
      const xxxx = (document.querySelector('#load') as HTMLElement).offsetParent?.scrollTop
      ul.scrollTop = xxxx
      console.log('xxx', [xxxx, ul])
      if (targetIsVisible.value) {
        pagination.pageNumber += 1
        // searchCustom.value = []
        // searchCustom.value.push(['pageNumber', '=', pagination.pageNumber])
        getData()
      }
    })

    onMounted(() => {
      // console.log('load', load)
    })

    return {
      onOpen,
      onSearch,
      valueSelect,
      options,
      isLoading,
      load,
      hasNextPage,
      func
    }
  }
})
